import { ComponentPropsWithRef, FC, ReactNode } from 'react';
import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  Typography,
  TypographyColor,
} from 'melp-design/components';
import { CardContent, Stack } from '@mui/material';
import ItemImages from 'components/marketplace/ItemCard/common/ItemImages';

interface Props {
  title?: string;
  description?: string;
  label?: string;
  image?: string;
  logo?: string;
  logoTooltip?: string;
  highlighted?: boolean;
  onClick?: () => void;
  tags?: Array<{ tag: string; color?: TypographyColor; visible?: boolean }>;
  action?: ComponentPropsWithRef<typeof Button>;
  additional?: ReactNode;
}

export const ListCard: FC<Props> = ({
  title,
  description,
  label,
  image,
  logo,
  logoTooltip,
  highlighted,
  onClick,
  tags,
  action,
  additional,
}) => {
  return (
    // "+2" because of the border
    <Card sx={{ width: 190 + 2, minHeight: 250 }} highlighted={highlighted}>
      <CardActionArea onClick={onClick}>
        <ItemImages image={image} logo={logo} logoTooltip={logoTooltip} />
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: '25px 15px 5px',
            textAlign: 'center',
            width: '100%',
            gap: 1,
            flexGrow: 1,
          }}
        >
          {title ? (
            <Typography variant="h3" maxLines={2}>
              {title}
            </Typography>
          ) : null}
          {description ? (
            <Typography variant="p2" color="textSecondary" maxLines={3}>
              {description}
            </Typography>
          ) : null}
          {tags?.length ? (
            <Stack
              flexDirection="row"
              flexWrap="wrap"
              justifyContent="center"
              columnGap={1}
            >
              {tags.map((tag, i) =>
                tag.visible === false ? null : (
                  <Typography variant="p2" color={tag.color} key={i}>
                    {tag.tag}
                  </Typography>
                ),
              )}
            </Stack>
          ) : null}
          {label ? (
            <Typography variant="h3" mt="auto">
              {label}
            </Typography>
          ) : null}
        </CardContent>
      </CardActionArea>

      {action ? (
        <CardActions>
          <Stack width="100%">
            <Button {...action} size="md" />
          </Stack>
        </CardActions>
      ) : null}

      {additional ? (
        <Stack sx={{ px: 1.5, py: '6px !important' }}>{additional}</Stack>
      ) : null}
    </Card>
  );
};
