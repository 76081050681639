import {
  IBaseRecognition,
  IRecognition,
  IRecognitionComment,
  IRecognitionsSettings,
} from 'types/income';
import { converters as tableConverters } from 'store/table';
import { convertEmbeddedReactions } from 'store/reactions';
import { converters as filesConverters } from 'store/files';
import { convertComment } from 'store/comments';
import { loaders } from './recognitions.loaders';
import {
  BaseRecognition,
  Recognition,
  RecognitionComment,
  RecognitionsSettings,
} from './recognitions.types';

const convertBaseRecognition = (
  recognition: IBaseRecognition,
): BaseRecognition => {
  return {
    id: recognition.id,
    status: recognition.approvalStatus,
    type: recognition.type,
    achievementTitle: recognition.achievementTitle,
    pointsReceived: recognition.pointsReceived,
    comment: recognition.comment,
    employee: recognition.employee
      ? {
          id: recognition.employee.id,
          firstName: recognition.employee.firstName,
          lastName: recognition.employee.lastName,
          fullName: [
            recognition.employee.firstName,
            recognition.employee.lastName,
          ]
            .filter(Boolean)
            .join(' '),
        }
      : null,
    sender: recognition.sender
      ? {
          id: recognition.sender.id,
          firstName: recognition.sender.firstName,
          lastName: recognition.sender.lastName,
          fullName: [recognition.sender.firstName, recognition.sender.lastName]
            .filter(Boolean)
            .join(' '),
        }
      : null,
    achievement: recognition.achievement
      ? {
          id: recognition.achievement.id,
          name: recognition.achievement.name,
          title: recognition.achievement.title,
        }
      : null,
    commentsCount: recognition.commentCount,
    reactions: convertEmbeddedReactions({
      summary: recognition.reactions,
      selected: recognition.selectedEmoji,
    }),
    createdAt: recognition.createdAt,
  };
};

const convertRecognition = (recognition: IRecognition): Recognition => {
  return {
    ...convertBaseRecognition(recognition),
    image: recognition.image
      ? filesConverters.getUploadedFile(recognition.image)
      : null,
    gifId: recognition.gifId,
  };
};

const convertRecognitions = (
  recognition: IBaseRecognition[],
): BaseRecognition[] => {
  return recognition.map(convertBaseRecognition);
};

const convertRecognitionsSettings = (
  settings: IRecognitionsSettings,
): RecognitionsSettings => {
  return {
    id: settings.id,
    pointName: settings.pointName,
    useMoney: settings.useMoney,
    currencyToPoint: settings.currencyToPoint,
    pointRewardPerRecognition: settings.pointRewardPerRecognition,
    limit: settings.limit,
    limitValue: settings.limitValue,
    limitPeriod: settings.limitPeriod,
    expirationEnabled: settings.hasPeriodicExpiration,
    expirationDay: settings.expirationDay ?? null,
    expirationMonth: settings.expirationMonth ?? null,
    expirationNotificationInDays: settings.expirationNotificationDays,
    expirationNotification: settings.expirationNotification,
    showPointsInFeed: settings.showPointsInFeed,
    allowComments: settings.allowComments,
    suggestionRules: settings.recognitionSuggestionRules,
    translations: settings.instructions.map((translation) => ({
      language: translation.language,
      id: translation.id,
      description: translation.content,
    })),
  };
};

const convertRecognitionComment = (
  comment: IRecognitionComment,
): RecognitionComment => {
  return {
    ...convertComment(comment),
    recognition: {
      message: comment.recognition.message,
      sender: comment.recognition.senderId
        ? {
            id: comment.recognition.senderId,
            fullName: comment.recognition.senderFullName ?? '',
          }
        : null,
      recipient: comment.recognition.recipientId
        ? {
            id: comment.recognition.recipientId,
            fullName: comment.recognition.recipientFullName ?? '',
          }
        : null,
    },
  };
};

const convertRecognitionComments = (
  comments: IRecognitionComment[],
): RecognitionComment[] => {
  return comments.map(convertRecognitionComment);
};

export const converters = {
  getRecognitions: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getRecognitions>>) => {
    const { total, page, pageSize, pages } = tableConverters.getTableData(data);

    return {
      items: convertRecognitions(data.data),
      total,
      page,
      pageSize,
      pages,
    };
  },
  getRecognition: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getRecognition>>) => {
    return convertRecognition(data);
  },
  getRecognitionsSettings: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getRecognitionsSettings>>) => {
    return convertRecognitionsSettings(data);
  },
  getComments: ({ data }: Awaited<ReturnType<typeof loaders.getComments>>) => {
    const { total, page, pageSize, pages } = tableConverters.getTableData(data);

    return {
      items: convertRecognitionComments(data.data),
      total,
      page,
      pageSize,
      pages,
    };
  },
};
