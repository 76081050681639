import { z } from 'zod';
import {
  adminClientsFiltersSchema,
  adminCompaniesFiltersSchema,
} from './admin-clients.schemas';

export const clientFeatures = [
  'benefits',
  'news',
  'recognition',
  'marketplace',
  'marketplaceV2',
  'claimReimbursement',
  'companyItems',
  'discounts',
  'manualExpenses',
  'comments',
  'ai',
] as const;
export type ClientFeature = (typeof clientFeatures)[number];

export const depositManagementMethods = ['company', 'clientAccount'] as const;
export type DepositManagementMethod = (typeof depositManagementMethods)[number];

export type AdminClientsFilters = z.infer<typeof adminClientsFiltersSchema>;

export type AdminCompaniesFilters = z.infer<typeof adminCompaniesFiltersSchema>;
